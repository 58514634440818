<template>
    <div class="price-negotiation">

        <div class="page-loader"
          v-if="loading"
        >
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <template
          v-else
        >
          <div class="page-title d-flex py-2 justify-content-between">
              <h1 class="h4 mb-0">Номенклатура № {{ item.id }} от {{ createdDate }}</h1>
          </div>

          <div class="mb-4">
              <div class="d-flex table-bordered mb-1">
                  <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Название</div>
                  <div class="px-2 py-1 flex-grow-1">{{ item.name }}</div>
              </div>
              
              <div class="d-flex table-bordered mb-1">
                  <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Мнемокод</div>
                  <div class="px-2 py-1 flex-grow-1">{{ item.mnemocode }}</div>
              </div>
              
              <div class="d-flex table-bordered mb-1">
                  <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Цена</div>
                  <div class="px-2 py-1 flex-grow-1">{{ item.price }}</div>
              </div>
              
              <div class="d-flex table-bordered mb-1">
                  <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Единица измерения</div>
                  <div class="px-2 py-1 flex-grow-1">{{ item.units && item.units[ 0 ] && item.units[ 0 ].name }}</div>
              </div>
              
              <div class="d-flex table-bordered mb-1">
                  <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Дата создания</div>
                  <div class="px-2 py-1 flex-grow-1">{{ createdDate }}</div>
              </div>
              
              <div class="d-flex table-bordered mb-1">
                  <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Дополнительная характеристика</div>
                  <div class="px-2 py-1 flex-grow-1">{{ item.additional }}</div>
              </div>
              
              <div class="d-flex table-bordered mb-1">
                  <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">ОКПД2</div>
                  <div class="px-2 py-1 flex-grow-1">{{ item.okpd }}</div>
              </div>
              
              <div class="d-flex table-bordered mb-1">
                  <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Файлы</div>
                  <div class="px-2 py-1 flex-grow-1">
                    <DownloadLink style="padding-right: 5px;"
                        v-for="(file, idx) of item.files"
                        :key="idx"
                        :id="file.id"
                        :from="`references/nomenclature/${ item.id }/files`"
                        :url="file.url"
                    />
                  </div>
              </div>
          </div>
        </template>
    </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'
  import DownloadLink from "@/components/UI/DownloadLink"

  export default {
    name: 'ReferenceBookItem',
    components: {
      DownloadLink
    },
    computed: {
      id() {
        return this.$route.params.id
      },
      createdDate() {
        if ( !this.item && !this.item.created_at )
          return null
        return moment( this.item.created_at ).format( 'DD.MM.YYYY в HH:mm' )
      }
    },
    data() {
      return {
        loading: true,
        item: null
      }
    },
    async mounted() {
      try {
        const response = await axios.get( `/references/nomenclature/${ this.id }` )
        this.item = response.data.data
        this.loading = false
      } catch( error ) {
        error
      }
    }
  }
</script>